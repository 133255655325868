import React from "react";
import GoogleMapReact from "google-map-react";
import { useStaticQuery, graphql } from "gatsby";
import { useEffect, useState } from "react";
import { CollectionIcon, MapIcon } from "@heroicons/react/solid";
import Header from "../Global/header";
import LocationMarker from "./LocationMarker";
import LocationInfoBox from "./locationInfoBox";



const Map = ({ center, zoom }) => {

  const data = useStaticQuery(graphql`
  {
    allPrismicDealers {
      edges {
        node {
          data {
            slug
            dealer_name {
              text
            }
            address
            state
            short_address
            mobile_number
            e_mail_address
            dealer_navigation_link {
              url
            }
            dealer_location {
              latitude
              longitude
            }
            dealer_images {
              image {
                gatsbyImageData
              }
            }
            cover_image {
              gatsbyImageData
            }
          }
          uid
        }
      }
    }
  }
`)  

  const [locationInfo, setLocationInfo] = useState(null);

  const markers = data.allPrismicDealers.edges.map((marker) => (
    <LocationMarker
      lat={marker.node.data.dealer_location.latitude}
      lng={marker.node.data.dealer_location.longitude}
      onClick={() =>
        setLocationInfo({
          name: marker.node.data.dealer_name.text,
          number: marker.node.data.mobile_number,
          email: marker.node.data.e_mail_address,
          dealer_name: marker.node.data.dealer_name.text,
          dealership_image: marker.node.data.cover_image,
          address: marker.node.data.address,
          shorthand_address: marker.node.data.short_address,
          slug: marker.node.data.slug,
          navigation: marker.node.data.dealer_navigation_link.url,
        })
      }
    ></LocationMarker>
  ));

console.log (process.env.REACT_APP_GOOGLE_MAP_KEY);
  return (
    
    <>
      <div className="h-screen w-screen">

      <div className = "absolute w-full z-10"><Header /></div>
        
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDRKL_Gm3KzqV4daaQ6gEIn99PDhKQaUjY" }}
          defaultCenter={center}
          defaultZoom={zoom}
          disableDefaultUI={true}
          options={{
            gestureHandling: "greedy",
            zoomControl: false,
            styles: [
                { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
                { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
                { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
                {
                  featureType: "administrative.locality",
                  elementType: "labels.text.fill",
                  stylers: [{ color: "#d59563" }],
                },
                {
                  featureType: "poi",
                  elementType: "labels.text.fill",
                  stylers: [{ color: "#d59563" }],
                },
                {
                  featureType: "poi.park",
                  elementType: "geometry",
                  stylers: [{ color: "#263c3f" }],
                },
                {
                  featureType: "poi.park",
                  elementType: "labels.text.fill",
                  stylers: [{ color: "#6b9a76" }],
                },
                {
                  featureType: "road",
                  elementType: "geometry",
                  stylers: [{ color: "#38414e" }],
                },
                {
                  featureType: "road",
                  elementType: "geometry.stroke",
                  stylers: [{ color: "#212a37" }],
                },
                {
                  featureType: "road",
                  elementType: "labels.text.fill",
                  stylers: [{ color: "#9ca5b3" }],
                },
                {
                  featureType: "road.highway",
                  elementType: "geometry",
                  stylers: [{ color: "#746855" }],
                },
                {
                  featureType: "road.highway",
                  elementType: "geometry.stroke",
                  stylers: [{ color: "#1f2835" }],
                },
                {
                  featureType: "road.highway",
                  elementType: "labels.text.fill",
                  stylers: [{ color: "#f3d19c" }],
                },
                {
                  featureType: "transit",
                  elementType: "geometry",
                  stylers: [{ color: "#2f3948" }],
                },
                {
                  featureType: "transit.station",
                  elementType: "labels.text.fill",
                  stylers: [{ color: "#d59563" }],
                },
                {
                  featureType: "water",
                  elementType: "geometry",
                  stylers: [{ color: "#17263c" }],
                },
                {
                  featureType: "water",
                  elementType: "labels.text.fill",
                  stylers: [{ color: "#515c6d" }],
                },
                {
                  featureType: "water",
                  elementType: "labels.text.stroke",
                  stylers: [{ color: "#17263c" }],
                },
              ],
          }}
        >
          {markers}
        </GoogleMapReact>
       

        {locationInfo && <LocationInfoBox info={locationInfo} />}
      </div>
    </>
  );
};

Map.defaultProps = {
  center: {
    lat: 20.5937,
    lng: 78.9629,
  },
  zoom: 6,
};

export default Map;