import React from "react";
import {
  MapIcon,
  PhoneIcon,
  ExternalLinkIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import { Link } from "gatsby";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";

const LocationInfoBox = (props) => {
  const { info } = props;
  return (
    <div className="absolute md:max-w-screen-sm lg:max-w-screen-md md:min-h-[265px] lg:h-[320px] w-full min-h-[250px] rounded md:rounded-xl overflow-hidden bottom-0 md:bottom-[30px] transform -translate-x-1/2 left-1/2 bg-gray-900 z-30 flex shadow-2xl">
      <div className="grid grid-cols-4">
        <GatsbyImage
          image={getImage(info.dealership_image)}
          className="bg-cover h-full w-full col-span-2"
        />
        <div className="col-span-2 p-4 lg:p-8 flex flex-col justify-between">
          <div className="flex flex-col">
            <h5 className="text-xs md:text-base font-bold text-blue-500 uppercase">
              Locate a Store
            </h5>
            <div className="my-2 flex flex-col space-y-1">
              <h2 className="text-lg md:text-3xl lg:text-3xl font-medium text-gray-300">
                {info.name}
              </h2>
              <h2 className="text-xs md:text-sm lg:text-base md:leading-normal font-light text-gray-400 leading-normal md:pr-4">
                {info.address}
              </h2>
            </div>
          </div>
          <ul className="flex flex-row justify-between items-center md:justify-start md:space-x-4">
            <a href={info.navigation} className="group" target="_blank">
              <li className=" p-3 bg-gray-800 shadow rounded group-hover:bg-blue-500">
                <MapIcon className="h-5 w-5 md:h-6 md:w-6 text-blue-500 group-hover:text-gray-800 flex-grow" />
              </li>
            </a>
            <a className="group" href={"tel:" + info.number}>
              <li className=" p-3 bg-gray-800 shadow rounded group-hover:bg-blue-500">
                <PhoneIcon className="h-5 w-5 md:h-6 md:w-6 text-blue-500 group-hover:text-gray-800 flex-grow" />
              </li>
            </a>
            <span className = "group">
              <Link to={"/dealers/" + info.slug}>
                <li className=" p-3 bg-gray-800 shadow rounded group-hover:bg-blue-500">
                  <ExternalLinkIcon className="h-5 w-5 md:h-6 md:w-6 text-blue-500 group-hover:text-gray-800 flex-grow" />
                </li>
              </Link>
            </span>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LocationInfoBox;