import React from "react";
import { LocationMarkerIcon } from "@heroicons/react/solid";

const LocationMarker = ({ lat, lng, onClick }) => {
  return (
    <div className=" hover:cursor-pointer" onClick={onClick}>
      <LocationMarkerIcon className="absolute -translate-y-1/2 -translate-x-1/2 h-7 w-7 fill-current text-blue-500 shadow" />
    </div>
  );
};

export default LocationMarker;
