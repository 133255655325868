import React from "react";
import Map from "../components/Dealers/Map";
import Layout from "../components/Global/layout";
import SEO from "../components/seo/SEO";

const Dealers = () => {
  return (
    <>
<SEO />
      <Map />
    </>
  );
};

export default Dealers;
